import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { JwtAuthService } from './auth/jwt-auth.service';
import { DateService } from './date.service';

@Injectable({
  providedIn: 'root'
})
export class AmplitudeService {
  amplitude: any;
  identifyEvent: any;

  constructor(
    private dateService: DateService,
    private jwtAuthService: JwtAuthService) {
    this.amplitude = (window as any).amplitude;
    this.identifyEvent = new this.amplitude.Identify();
  }

  get getAmplitude() {
    return this.amplitude;
  }

  init(userId: any) {
    const user = this.jwtAuthService.getUser();
    userId = userId || user?.id;
    if (userId) {
      userId = userId.toString();
      for (let i = 0; i < (5 - userId.length); i++) {
        userId = '0' + userId;
      }
    }
    if (userId) {
      this.amplitude.init(environment.amplitude, userId);
    } else {
      this.amplitude.init(environment.amplitude);
    }
  }

  addIdentifyEvent(index: IdentifyNameAmplitude, value: any) {
    console.log('Amplitude.addEdentifyEvent: ', index, value);
    this.identifyEvent.add(index, value);
  }

  setIdentify() {
    const user = this.jwtAuthService.getUser();
    if (user) {
      let age: any;
      const { personProperties } = user;
      if (personProperties) {
        const { birthdate, biologicalSex } = personProperties;
        age = this.dateService.diffByYears(birthdate);
        this.addIdentifyEvent('biological_sex', biologicalSex);
        this.addIdentifyEvent('age', age);
      }
    }
    this.amplitude.identify(this.identifyEvent);
  }

  sendEvent(eventName: EventNameAmplitude, props?: Record<string, any>) {
    this.amplitude.track(
      eventName,
      props
    );
    console.log('Amplitude: ', eventName, props);
  }

  trackPageViewed(trackInfos: TrackInfos) {
    const {domain, path, title, location} = trackInfos;
    console.log(trackInfos);
    this.amplitude.track('[Amplitude] Page Viewed', {
      '[Amplitude] Page Domain': domain,
      '[Amplitude] Page Location': location,
      '[Amplitude] Page Title': title,
      '[Amplitude] Page Path': path,
      'referring_domain': window.location.origin,
    });
  }
}

export interface TrackInfos{
  domain: string;
  location: string;
  title: string;
  path: string;
}

type IdentifyNameAmplitude =
  'biological_sex' |
  'age' |
  'ho_id' |
  'ho_name' |
  'rh_id' |
  'rh_name';

type EventNameAmplitude = 'cell_phone_code_validated' |
  'terms_accept_clicked' |
  'terms_display_clicked' |
  'url_signature_failed' |
  'cell_phone_is_valid' |
  'validation_failed' |
  'onboarding_finished' |
  'answer_created' |
  'answer_updated' |
  'form_submitted' |
  'previous_exams_skipped' |
  'previous_exams_sent' |
  'exams_uploaded' |
  'new_exams_request_downloaded' |
  'contact_support_clicked' |
  'patient_summary_viewed' |
  'patient_summary_tab_clicked' |
  'patient_summary_referral_button_clicked' |
  'patient_summary_referral_downloaded' |
  'patient_summary_self_care_clicked' |
  'page_viewed';
